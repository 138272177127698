

    // ### Foundation Init
    $(document).foundation();

    // ### Vars
    var $bod = $("body"),
        b = document.body,
        $main = $("#main"),
        primary_menu = document.querySelector("#header_nav"),
        primary_menu_trigger = document.querySelector("#trigger_menu");


    // ### Mobile Menu
    if (primary_menu){

      // Function to open/close menu
      var toggleMenu = function(e){
          e.preventDefault();

          if(b.classList.contains('nav-open')){
              $(document).trigger("Drilldown#event:closed");
          }

          b.classList.toggle('nav-open');
      };

      // Clone and keep events the primary navigation
      var primary_menu_mobile = primary_menu.cloneNode(true);
      primary_menu_mobile.classList.add("mobile");
      primary_menu_mobile.setAttribute('id','primary_navigation_mobile');
      b.appendChild(primary_menu_mobile);
      // Change label/input IDs
      var mobile_switch_input = document.querySelector(".mobile input#is_pro");
      var mobile_switch_paddle = document.querySelector(".mobile label.switch-paddle");
      mobile_switch_input.setAttribute('id','is_pro_mobile');
      mobile_switch_paddle.setAttribute('for','is_pro_mobile');


      // Create an overlay
      var menu_overlay = document.createElement("div");
      menu_overlay.classList.add("menu-overlay");
      b.appendChild(menu_overlay);


      // Event Listeners
      primary_menu_trigger.addEventListener('click', toggleMenu);
      menu_overlay.addEventListener('click', toggleMenu);
    }



    // ### Pop up
    $('.open-invoice-pop-in.consommation-volume').on('click', function (e) {
        e.preventDefault();
        // $('#hint_facture').foundation('open');
        $('#consommation-volume').foundation('open');
    });
    $('.open-invoice-pop-in.num_pce').on('click', function (e) {
        e.preventDefault();
        $('#num_pce').foundation('open');
    });
    $('.open-invoice-pop-in.num_compteur').on('click', function (e) {
        e.preventDefault();
        $('#num_compteur').foundation('open');
    });
    $('.open-invoice-pop-in.index').on('click', function (e) {
        e.preventDefault();
        $('#index').foundation('open');
    });


    // ### Scroll Reveal
    // Easy scroll animations for web and mobile
    // @url https://github.com/jlmakes/scrollreveal.js
    // var init_scrollreveal = (function initScrollReveal(el){
    //
    //     window.sr = new ScrollReveal();
    //
    //     sr.reveal('.sr', {
    //         duration: 800,
    //         distance: '5vh',
    //         delay: 200,
    //         opacity: 0,
    //         scale: 1,
    //         viewFactor: 0.4,
    //         //viewOffset: { top: 100 },
    //         easing: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // easeOutCubic
    //         afterReveal: function(domEl) {
    //             if( !domEl.classList.contains('sr-done') ){
    //                 domEl.classList.add('sr-done');
    //             }
    //         }
    //     });
    //
    //     return initScrollReveal;
    // })();
